<template>
  <div class="address_input_google">
    <el-table
      class="table-responsive table"
      style="width: 100%"
      :header-cell-style="{background: '#eef1f6'}"
      :cell-style="{padding: '10px 0', background: '#fff'}"
      :data="cur_address_list"
    >
      <!-- @row-click="address_row_clicked_event" -->
      <el-table-column label="Name" prop="Name" class="p-0" min-width="100px">
        <template slot="header">
          <div class="w-100 p-0 text-center">{{ $t("Name") }}</div>
        </template>
      </el-table-column>
      <el-table-column label="Address">
        <template slot="header">
          <div class="w-100 p-0 text-center">{{ $t("Address") }}</div>
        </template>
        <template v-slot="{ row }">
          {{ address_combination(row) }}
        </template>
      </el-table-column>
      <el-table-column label="Remarks" prop="Remarks">
        <template slot="header">
          <div class="w-100 p-0 text-center">{{ $t("Remarks") }}</div>
        </template>
      </el-table-column>
      <el-table-column label="#">
        <template slot="header">
          <el-tooltip
            effect="dark"
            content="Add"
            placement="top"
            style="margin: auto"
            :openDelay="1000"
          >
            <el-button
              type="info"
              class="button"
              icon="el-icon-plus"
              @click="start_new_address()"
            >
            </el-button>
          </el-tooltip>
        </template>
        <template v-slot="{ row }">
          <div style="display: flex; justify-content: center;">
            <el-tooltip
              effect="dark"
              content="Set as Billing"
              placement="top"
              :openDelay="1000"
            >
              <el-button
                class="button command_button"
                :class="{ green_button: row.BillingFlag }"
                @click="set_billing_address(row, $event)"
              >
                B
              </el-button>
            </el-tooltip>
            <el-tooltip
              effect="dark"
              content="Set as Shipping"
              placement="top"
              :openDelay="1000"
            >
              <el-button
                class="button command_button"
                :class="{ green_button: row.ShippingFlag }"
                @click="set_shipping_address(row, $event)"
              >
                S
              </el-button>
            </el-tooltip>
            <el-tooltip
              effect="dark"
              content="Delete"
              placement="top"
              :openDelay="1000"
            >
              <el-button
                type="danger"
                class="button"
                icon="el-icon-delete"
                @click="delete_address(row)"
              >
              </el-button>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div class="full_screen_cover" v-if="newAddressInput" />
    <div class="new_address" v-if="newAddressInput">
      <fieldset>
        <legend>{{ $t("Address") }}</legend>
        <b-card>
          <el-row :gutter='10' style="margin-left: 10px; margin-bottom: 30px">
            <el-col :span='24'>
              <b-input type="text" v-model="search" @input="onChange" :placeholder="$t('Please input your address')" autocomplete="off" :disabled="disable_search"/>
              <ul v-show="isOpen" class="autocomplete3-results">
                <li v-for="(result, i) in search_results" :key="i" @click="setResult(result)" class="autocomplete2-result">{{ result }}</li>
              </ul>
            </el-col>
          </el-row>
          <el-row :gutter="10" style="margin-left: 10px;">
            <el-col :span="6">
              <base-input :label="$t('Address Name')">
                <b-input
                  :placeholder="$t('Address Name')"
                  class="form-control"
                  v-model="cur_address.Name"
                />
              </base-input>
            </el-col>
            <el-col :span="6">
              <base-input :label="'*' + $t('Address Line') + ' 1'">
                <b-input
                  :placeholder="$t('Address Line') + ' 1'"
                  class="form-control"
                  v-model="cur_address.Street1"
                  @input="validateInputField"
                  aria-describedby="input-live-help input-live-feedback"
                  :state="street1_State"
                />
                <b-form-invalid-feedback id="input-live-feedback">
                  Enter Street Address
                </b-form-invalid-feedback>
              </base-input>
            </el-col>
            <el-col :span="6">
              <base-input :label="$t('Address Line') + ' 2'">
                <b-input
                  :placeholder="$t('Address Line') + ' 2'"
                  class="form-control"
                  v-model="cur_address.Street2"
                />
              </base-input>
            </el-col>
            <el-col :span="6">
              <base-input :label="'*' + $t('Postal Code')">
                <b-input
                  :placeholder="$t('Postal Code')"
                  class="form-control"
                  v-model="cur_address.ZIP"
                  @input="validateInputField"
                  aria-describedby="input-live-help input-live-feedback"
                  :state="postalCode_State"
                />
                <b-form-invalid-feedback id="input-live-feedback">
                  Enter Postal Code
                </b-form-invalid-feedback>
              </base-input>
            </el-col>
          </el-row>
          <el-row :gutter="10" style="margin-left: 10px; margin-bottom: 60px">
            <el-col :span="6">
              <base-input :label="$t('Country')">
                <!-- <select
                  class="form-control"
                  v-model="cur_address.CountryIDn"
                  @change="changed_country"
                >
                  <option
                    v-for="(country, countryIDn) in countrylist"
                    :key="countryIDn"
                    :value="countryIDn"
                  >
                    {{ country.Country }}
                  </option>
                </select> -->
                <!-- {{ countrylist }} -->
                <el-select
                  class="el-dropdown2"
                  v-model="cur_address.CountryIDn"
                >
                  <el-option v-for="item in countrylist"
                            :key="item.IDn"
                            :value="item.IDn"
                            :label="item.Country"
                            @click.native="changed_country">
                  </el-option>
                </el-select>
              </base-input>
            </el-col>
            <el-col :span="6">
              <base-input :label="$t('Province')">
                <!-- <select
                  class="form-control"
                  v-model="cur_address.ProvinceIDn"
                  @change="changed_province"
                >
                  <option
                    v-for="(province,idx) in cur_provincelist"
                    :key="idx"
                    :value="province.IDn"
                  >
                    {{ province.Province }}
                  </option>
                </select> -->
                <el-select
                  class="el-dropdown2"
                  v-model="cur_address.ProvinceIDn"
                >
                  <el-option v-for="item in cur_provincelist"
                            :key="item.IDn"
                            :value="item.IDn"
                            :label="item.Province"
                            @click.native="changed_province">
                  </el-option>
                </el-select>
              </base-input>
            </el-col>
            <el-col :span="6">
              <base-input :label="'*' + $t('City')">
                <InputAutocomplete
                  :placeholder="$t('City')"
                  :key="cur_address.AddressCity"
                  :items="citylist"
                  :initval="cur_address.AddressCity"
                  :mandatory="true"
                  :state="city_State"
                  v-model="cur_address.AddressCity"
                  @input="setinput_city"
                ></InputAutocomplete>
              </base-input>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6" :offset="6">
              <el-button type="primary" @click="cancel_edit_address">
                {{ $t("Cancel") }}
              </el-button>
            </el-col>
            <el-col :span="6" :offset="6">
              <el-button type="primary" @click="validateForm">
                {{ $t("Submit") }}
              </el-button>
            </el-col>
          </el-row>
        </b-card>
      </fieldset>
    </div>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  Button,
  Pagination,
  Collapse,
  CollapseItem,
  Row,
  Col,
  Select,
  Option,
  OptionGroup,
} from "element-ui";
import InputAutocomplete from "./InputAutocomplete";

const axios = require("axios");
export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Row.name]: Row,
    [Col.name]: Col,
    [Select.name]: Select,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup,
    [Pagination.name]: Pagination,
    InputAutocomplete,
  },
  name: "AddressInput",
  props: {
    address_type: {
      type: String,
      required: true,
      default: "customer",
    },
    token: {
      type: String,
      required: true,
      default: "",
    },
    user_id: {
      type: String,
      required: true,
      default: "",
    },
    ReferenceIDn: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      newAddressInput: false,
      search: "",
      search_results: [],
      isOpen: false,
      disable_search: false,

      cur_address_list: [],
      cur_address: {},
      new_address: {
        IDn: 0,
        Type: "",
        ReferenceIDn: 0,
        BillingFlag: 0,
        ShippingFlag: 0,
        Status: 1,
        Name: "",
        Street1: "",
        Street2: "",
        AddressCity: "",
        ProvinceIDn: '2',
        AddressProvince: "British Columbia",
        CountryIDn: '1',
        AddressCountry: "Canada",
        ZIP: "",
        Remarks: "",
      },

      cur_provincelist: [],
      countrylist: [],
      provincelist: [],
      citylist: [],

      street1_State: true,
      city_State: true,
      postalCode_State: true,
    };
  },
  methods: {
    onChange() {
      if(this.search === ""){
        this.isOpen = false;
      } 
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("w", this.search);
      bodyFormData.append("cIDn", this.cur_address.CountryIDn);
      bodyFormData.append("postalcode", "1");  // or 0

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/MultiAdd/search",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.search_results = rt.data.addresslist;
              if(rt.data.addresslist.length > 0){
                that.isOpen = true;
              }
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            alert(response.statusText);
          }
        })
        .catch(function (error) {
          that.$router.push("/login");
        });
    },
    setResult(result) {
      // Result: 110 Charles Street East, Toronto, ON, Canada
      this.search = result;
      var addrs = result.split(",");
      if (addrs[0] != undefined) {
        this.cur_address.Street1 = addrs[0];
        if (addrs[1] != undefined) {
          this.cur_address.AddressCity = addrs[1].trim();
        }
        var CIDn = 1; // Default to Canada
        if (addrs[3] != undefined) {
          var country = addrs[3].trim();
          for (var idx in this.countrylist) {
            if (this.countrylist[idx].Country == country) {
              CIDn = this.countrylist[idx].IDn;
              this.cur_address.AddressCountry = this.countrylist[idx].Country;
              break;
            }
          }
        }
        this.cur_address.CountryIDn = CIDn;
        var PIDn = 2; // Default to British Columbia
        if (addrs[2] != undefined) {
          var province = addrs[2].trim();
          for (var idx in this.provincelist) {
            if (this.provincelist[idx].ProvinceID == province) {
              PIDn = this.provincelist[idx].IDn;
              this.cur_address.AddressProvince = province;
              break;
            }
          }
        }
        if (addrs[4] != undefined) {
          this.cur_address.ZIP = addrs[4].trim();
        }
        this.cur_address.ProvinceIDn = PIDn;
      } else {
        this.cur_address.Street1 = result;
      }
      this.isOpen = false;
    },
    address_combination(address) {
      let rt = "";
      rt += address.Street2
        ? address.Street1 + " " + address.Street2
        : address.Street1;
      rt += address.AddressCity ? (rt ? ", " : "") + address.AddressCity : "";
      rt += address.AddressProvince
        ? (rt ? ", " : "") + address.AddressProvince
        : "";
      rt += address.AddressCountry
        ? (rt ? ", " : "") + address.AddressCountry
        : "";
      rt += address.ZIP ? (rt ? ", " : "") + address.ZIP : "";
      return rt;
    },
    cancel_edit_address() {
      this.newAddressInput = false;
    },
    start_new_address() {
      this.cur_address = JSON.parse(JSON.stringify(this.new_address));
      setTimeout(()=>{
        this.calculate_el_select_width()
      },50); // timer is used to avoid funciton call before left menu is loaded
      this.search = "";
      this.search_results = [];
      this.newAddressInput = true;
      this.street1_State = true;
      this.postalCode_State = true;
      this.city_State = true;
    },
    validateInputField(){
      if(this.cur_address.Street1 != ''){
        this.street1_State = true;
      }
      if(this.cur_address.AddressCity != ''){
        this.city_State = true;
      }
      if(this.cur_address.ZIP != ''){
        this.postalCode_State = true;
      }
    },
    checkNoEmptyInput(){
      if(!this.newAddressInput){
        return true;
      }
      let passed = true;
      if(this.cur_address.Street1 == ''){
        this.street1_State = false;
        passed = false;
      } else {
        this.street1_State = true;
      }
      if(this.cur_address.AddressCity == ''){
        this.city_State = false;
        passed = false;
      } else {
        this.city_State = true;
      }
      if(this.cur_address.ZIP == ''){
        this.postalCode_State = false;
        passed = false;
      } else {
        this.postalCode_State = true;
      }
      return passed;
    },
    validateForm(){
      let allPassed = this.checkNoEmptyInput();
      if(allPassed){
        this.submit_address();
      } else {
        const interval = setInterval(()=> {
          allPassed = this.checkNoEmptyInput();
          if(allPassed){
            clearInterval(interval);
          }
        }, 500);
      }
    },
    async set_billing_address(address, event) {
      if (event) {
        event.preventDefault();
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", this.cur_opt);
      var that = this;
      bodyFormData.append("IDn", address.IDn);
      bodyFormData.append("ReferenceIDn", this.ReferenceIDn);
      bodyFormData.append("Type", this.address_type);
      // console.log("address.IDn", address.IDn );
      // console.log("this.ReferenceIDn", this.ReferenceIDn );
      axios({
        method: "post",
        url: "/Api/Web/MultiAdd/set_billing",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              // console.log("rt.data", rt.data);
              that.cur_address_list = rt.data;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            alert(response.statusText);
          }
        })
        .catch(function (error) {
          that.$router.push("/login");
        });
    },
    async set_shipping_address(address, event) {
      if (event) {
        event.preventDefault();
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", this.cur_opt);
      var that = this;
      bodyFormData.append("IDn", address.IDn);
      bodyFormData.append("ReferenceIDn", this.ReferenceIDn);
      bodyFormData.append("Type", this.address_type);
      axios({
        method: "post",
        url: "/Api/Web/MultiAdd/set_shipping",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_address_list = rt.data;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            alert(response.statusText);
          }
        })
        .catch(function (error) {
          that.$router.push("/login");
        });
    },
    async submit_address() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      for (var key in this.cur_address) {
        if (key == "ReferenceIDn") {
          bodyFormData.append("ReferenceIDn", this.ReferenceIDn);
        } else if (key == "Type") {
          bodyFormData.append("Type", this.address_type);
        } else {
          bodyFormData.append(key, this.cur_address[key]);
        }
      }

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/MultiAdd/submit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              var newaddr = rt.data;
              that.cur_address_list.push(newaddr);
              that.$emit('address_added', newaddr.IDn);
              that.newAddressInput = false;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            alert(response.statusText);
          }
        })
        .catch(function (error) {
          //XXXXX that.$router.push("/login");
        });
    },
    delete_address(row) {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("ReferenceIDn", row.ReferenceIDn);
      bodyFormData.append("IDn", row.IDn);
      bodyFormData.append("Type", row.Type);
      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/MultiAdd/delete",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            that.$emit('address_deleted', row.IDn);
            var rt = response.data;
            if (rt.status == 0) {
              for (var i=0; i<that.cur_address_list.length; i++) {
                if (that.cur_address_list[i].IDn == row.IDn) {
                  that.cur_address_list.splice(i,1);
                  break;
                }
              }
              that.$emit('cur_address_list', that.cur_address_list);
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            alert(response.statusText);
          }
        })
        .catch(function (error) {
          // that.$router.push("/login");
        });
    },

    setinput_city(city) {
      this.cur_address.AddressCity = city;
    },
    changed_province() {
      if (this.cur_provincelist.length <= 0) {
        return;
      }

      if (
        !this.cur_address.ProvinceIDn ||
        this.cur_address.ProvinceIDn == undefined ||
        this.cur_address.ProvinceIDn <= 0
      ) {
        this.cur_address.ProvinceIDn = this.cur_provincelist[0].IDn;
      }
      this.cur_address.AddressProvince = "";
      for (var i=0; i<this.cur_provincelist.length; i++) {
        if (this.cur_provincelist[i].IDn == this.cur_address.ProvinceIDn) {
          this.cur_address.AddressProvince = this.cur_provincelist[i].Province;
        }
      }
      if (this.cur_address.AddressProvince == "") {
        // Didn't find Province
        this.cur_address.ProvinceIDn = this.cur_provincelist[0].IDn;
      }
    },
    changed_country() {
      if (
        !this.cur_address.CountryIDn ||
        this.cur_address.CountryIDn == undefined ||
        this.cur_address.CountryIDn <= 0
      ) {
        this.cur_address.CountryIDn = 1; // Set default to Canada
      }

      for (var idx in this.countrylist) {
        if (this.countrylist[idx].IDn == this.cur_address.CountryIDn) {
          this.cur_address.AddressCountry = this.countrylist[idx].Country;
        }
      }
      // If country is "China", disable search function
      if(this.cur_address.AddressCountry === 'China'){
        this.disable_search = true;
      } else {
        this.disable_search = false;
      }
      this.cur_provincelist = [];
      for (var idx in this.provincelist) {
        if (this.provincelist[idx].CountryIDn == this.cur_address.CountryIDn) {
          this.cur_provincelist.push(this.provincelist[idx]);
        }
      }
      this.changed_province();
    },
    async initAddressInput() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      var that = this;
      bodyFormData.append("ReferenceIDn", this.ReferenceIDn);
      bodyFormData.append("address_type", this.address_type);

      axios({
        method: "post",
        url: "/Api/Web/MultiAdd/initAddressInput",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_address_list = rt.data.address_list;
              that.countrylist = rt.data.countries;
              that.provincelist = rt.data.provinces;
              that.citylist = rt.data.cities;
              that.cur_provincelist = [];
              that.changed_country()
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            alert(response.statusText);
          }
        })
        .catch(function (error) {
          that.$router.push("/login");
        });
    },
    init_data() {
      if (!this.token || !this.user_id) {
        this.$router.push("/login");
        return;
      }
      this.initAddressInput();
    },
    calculate_el_select_width(){
      for(var i = 0; i < document.getElementsByClassName("el-dropdown2").length; i++){
        document.getElementsByClassName("el-dropdown2")[i].children[0].children[0].style.borderRadius = "0.375rem";
        document.getElementsByClassName("el-dropdown2")[i].style.width = document.getElementsByClassName("el-dropdown2")[0].parentElement.clientWidth + "px";
        // document.getElementsByClassName("el-dropdown2")[i].style.width = (parseFloat(document.getElementsByClassName("el-dropdown2")[i].style.width) - 10) + 'px'; // -10 because el-row gutter is 10px
        document.getElementsByClassName("el-dropdown2")[i].children[0].children[0].style.height = "43px";
      }
    },
  },
  mounted() {
    this.init_data();
    window.addEventListener('resize', this.calculate_el_select_width);
  },
  unmounted(){
    window.removeEventListener('resize', this.calculate_el_select_width);
  },
};
</script>
<style>
.address_input_google {
  position: relative;
}

.address_input_google .autocomplete3-results {
    padding: 0;
    margin: 5px 0;
    border: 1px solid #cad1d7;
    border-radius: 4px;
    height: 150px;
    box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
    overflow: auto;
    position: absolute;
    background-color: white;
    color: #052b24;
    width: calc(100% - 10px);
    z-index: 1;
    font-size: 1rem;
  }

.address_input_google .autocomplete2-result {
  list-style: none;
  text-align: left;
  padding: 4px 2px;
  cursor: pointer;
}

.address_input_google .autocomplete2-result:hover {
  background-color: #4aae9b;
  color: white;
}

.address_input_google .full_screen_cover {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 10;
  opacity: 0.5;
  background: #29343a;
  color: white;
}

.address_input_google .new_address {
  padding: 2%;
  position: fixed;
  top: calc(50% - 200px);
  left: 15%;
  width: 70%;
  z-index: 10;
  background: white;
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #cad1d7;
}
.was-validated .form-control:valid, .form-control.is-valid, .was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #cad1d7;
}
</style>